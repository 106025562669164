import * as React from "react";
import { useGetConfigAppQuery } from "../../../utils/api/config/config.api";
import { createMarkup } from "../../../utils/Utils";
import FooterPage from "../../auth/FooterPage";
import { AlertInfo } from "../../common/Alert";
import FrontFooter from "../FrontFooter/FrontFooter";
import FrontHeader from "../FrontHeader/FrontHeader";
import { TextSkeleton } from "../../admin/Parametres/Steps/CguAdmin";

const CguPage = () => {
  const { data,isLoading } = useGetConfigAppQuery();
  return (
    <div className="landing-page-component">
      <FrontHeader />
        <div className="page-content pb-5 pb-5">
          <h1 className="custom-page-title">
            Politique Générale de Ventes
          </h1>
          <div className="container pb-4">
              <div className="custom-politique-page-content pt-5">
                  <h3 className="custom-sm-title pb-1 pb-4">
                    1. Préambule
                  </h3>
                  <div className="custom-sm-value">
                    Les présentes conditions générales régissent les ventes effectuées par la marque <strong>Aduuna Bul Comprendre</strong>, spécialisée dans la création et la vente de vêtements et accessoires africains et dans les services relatifs à l’Industrie Créative. Toute commande implique l'acceptation intégrale des CGVs par le client.                  
                  </div>
                  <div className="content-all-politique pt-4 pb-3">
                      <div className="content-politique-item">
                          <h3 className="custom-sm-title pb-4">
                            2. Produits et Services
                          </h3>
                          <div className="custom-sm-value">
                            <ul className="custom-instruction-list">
                              <li className="listitem">
                                Les produits & services proposés sont décrits avec précision sur le site ou dans les catalogues.                              
                              </li>
                              <li className="listitem">
                                Les photos sont non contractuelles, des variations mineures peuvent exister.                              
                              </li>
                              <li className="listitem">
                                Tous les produits sont fabriqués avec des matériaux de qualité, respectant l’identité culturelle africaine.                              
                              </li>
                            </ul>
                          </div>
                      </div>
                      <div className="content-politique-item pt-5">
                          <h3 className="custom-sm-title pb-2">
                            3. Prix et Paiement
                          </h3>
                          <ul className="custom-instruction-list">
                            <li className="listitem">
                              Les prix sont exprimés en FCFA ou en euros (ou toute autre devise spécifiée).  
                            </li>
                            <li className="listitem">
                              Le paiement peut être effectué par carte bancaire, virement, ou mobile money (Orange Money, Wave, etc.).                            
                            </li>
                            <li className="listitem">
                              Les taxes applicables et frais de livraison sont indiqués avant validation de la commande.                            
                            </li>
                          </ul>
                      </div>
                      <div className="content-politique-item pt-5">
                          <h3 className="custom-sm-title pb-4">
                              4. Commandes
                          </h3>
                          <div className="custom-sm-value">
                              <ul className="custom-list">
                                  <li className="list-item">
                                    Les commandes sont passées en ligne, via le Concept Store ou via des points de vente partenaires.
                                  </li>
                                  <li className="list-item">
                                    Une confirmation est envoyée après paiement intégral.
                                  </li>
                              </ul>
                          </div>
                      </div>
                      <div className="content-politique-item pt-5">
                          <h3 className="custom-sm-title pb-4">
                              5. Livraison
                          </h3>
                          <div className="custom-sm-value">
                              <ul className="custom-list">
                                  <li className="list-item">
                                    Les délais de livraison varient en fonction de la destination et sont mentionnés lors de l’achat.
                                  </li>
                                  <li className="list-item">
                                  <strong>Aduuna Bul Comprendre</strong> ne peut être tenue responsable des retards causés par des tiers (transporteurs).
                                  </li>
                              </ul>
                          </div>
                      </div>
                      <div className="content-politique-item pt-5">
                          <h3 className="custom-sm-title pb-4">
                              6. Retours et Échanges
                          </h3>
                          <div className="custom-sm-value">
                              <ul className="custom-list">
                                  <li className="list-item">
                                    Les retours sont acceptés dans un délai de 14 jours après réception, sous réserve que le produit soit non porté et dans son emballage d'origine.
                                  </li>
                                  <li className="list-item">
                                    Les frais de retour sont à la charge du client, sauf en cas de produit défectueux.
                                  </li>
                              </ul>
                          </div>
                      </div>
                      <div className="content-politique-item pt-5">
                          <h3 className="custom-sm-title pb-4">
                              7. Propriété Intellectuelle
                          </h3>
                          <div className="custom-sm-value">
                              <ul className="custom-list">
                                  <li className="list-item">
                                    Tous les designs, logos, et contenus de la marque sont protégés par les lois sur la propriété intellectuelle.                                  
                                  </li>
                                  <li className="list-item">
                                    Toute reproduction, utilisation ou distribution des photographies et visuels appartenant à <strong>Aduuna Bul Comprendre</strong> à des fins personnelles ou commerciales est strictement interdite sans autorisation écrite préalable de la marque. Toute infraction pourra donner lieu à des poursuites judiciaires.                                  
                                  </li>
                              </ul>
                          </div>
                      </div>
                      <div className="content-politique-item pt-5">
                          <h3 className="custom-sm-title pb-4">
                            8. Protection des Données                          
                          </h3>
                          <div className="custom-sm-value">
                              <ul className="custom-list">
                                  <li className="list-item">
                                    Les données personnelles collectées sont utilisées uniquement pour traiter les commandes et améliorer le service client, conformément aux lois en vigueur.
                                  </li>
                              </ul>
                          </div>
                      </div>
                      <div className="content-politique-item pt-5">
                          <h3 className="custom-sm-title pb-4">
                              9. Droit Applicable
                          </h3>
                          <div className="custom-sm-value">
                              <ul className="custom-list">
                                <li className="list-item">
                                  Les présentes Conditions Générales de Vente sont soumises au droit sénégalais.
                                </li>
                              </ul>
                          </div>
                      </div>
                      <div className="content-politique-item pt-5">
                          <h3 className="custom-sm-title pb-4">
                              10. Service Client
                          </h3>
                          <div className="custom-sm-value">
                            Pour toute question ou réclamation, contactez-nous à : 
                              <ul className="custom-list">
                                <li className="list-item">
                                  <strong>Email :</strong> abcloveafricawear@gmail.com
                                </li>
                                <li className="list-item">
                                  <strong>Téléphone :</strong> +221 77 462 09 09
                                </li>
                              </ul>
                          </div>
                      </div>
                  </div>

              </div>
          </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default CguPage;
