import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import { Input } from "../../common/Input";

function Contact() {

    return(
        <div className="landing-page-component">
            <FrontHeader />
            <div className="page-content pb-5 pb-5">
                <h1 className="custom-page-title">
                    Contact
                </h1>
                <div className="container pt-4 pb-4 ">
                    <div className="row row-contact">
                        <div className="col-md-5 col-infos">
                            <div className="custom-item">
                                <div className="item-sm-title">
                                    ADRESSE
                                </div>
                                <div className="item-sm-value">
                                    Lot 9, OUAKAM Cité Bira Comico, Dakar
                                </div>
                            </div>
                            <div className="custom-item pt-3">
                                <div className="item-sm-title">
                                    MOBILE
                                </div>
                                <div className="item-sm-value">
                                    (+221) 77 77 462 09 09
                                </div>
                            </div>
                            <div className="custom-item pt-3">
                                <div className="item-sm-title">
                                    EMAIL
                                </div>
                                <div className="item-sm-value">
                                    commercial@abcloveafricawear.com
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 col-send m-b-5">
                            <div className="maps-container">
                                <iframe 
                                    className="maps-frame"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3858.753716492957!2d-17.4829701!3d14.7265114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xec16d1dea592683%3A0x654801f0f27cba39!2sABC-%20Love%20Africa.%20Wear%20Africa.!5e0!3m2!1sfr!2ssn!4v1734514802285!5m2!1sfr!2ssn" 
                                    width="600" 
                                    height="360" 
                                    style={{border:0}}
                                    allowFullScreen={true}
                                    loading="lazy" 
                                    referrerPolicy="no-referrer-when-downgrade">
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <FrontFooter />
        </div>
    )
}

export default Contact